.f-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.f-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.f-full {
  -webkit-box-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.f-noshrink {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.f-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.f-vcenter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.panel {
  overflow: hidden;
  text-align: left;
  margin: 0;
  border: 0;
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.panel-header,
.panel-body {
  border-width: 1px;
  border-style: solid;
}
.panel-header {
  padding: 5px;
  position: relative;
}
.panel-title {
  background: url('images/blank.gif') no-repeat;
}
.panel-header-noborder {
  border-width: 0 0 1px 0;
}
.panel-body {
  overflow: auto;
  border-top-width: 0;
  padding: 0;
}
.panel-body-noheader {
  border-top-width: 1px;
}
.panel-body-noborder {
  border-width: 0px;
}
.panel-body-nobottom {
  border-bottom-width: 0;
}
.panel-with-icon {
  padding-left: 18px;
}
.panel-icon,
.panel-tool {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  height: 16px;
  overflow: hidden;
}
.panel-icon {
  left: 5px;
  width: 16px;
}
.panel-tool {
  right: 5px;
  width: auto;
}
.panel-tool a {
  display: inline-block;
  width: 16px;
  height: 16px;
  opacity: 0.6;
  filter: alpha(opacity=60);
  margin: 0 0 0 2px;
  vertical-align: top;
}
.panel-tool a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  background-color: #eee;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border-radius: 0px 0px 0px 0px;
}
.panel-loading {
  padding: 11px 0px 10px 30px;
}
.panel-noscroll {
  overflow: hidden;
}
.panel-fit,
.panel-fit body {
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
}
.panel-loading {
  background: url('images/loading.gif') no-repeat 10px 10px;
}
.panel-tool-close {
  background: url('images/panel_tools.png') no-repeat -16px 0px;
}
.panel-tool-min {
  background: url('images/panel_tools.png') no-repeat 0px 0px;
}
.panel-tool-max {
  background: url('images/panel_tools.png') no-repeat 0px -16px;
}
.panel-tool-restore {
  background: url('images/panel_tools.png') no-repeat -16px -16px;
}
.panel-tool-collapse {
  background: url('images/panel_tools.png') no-repeat -32px 0;
}
.panel-tool-expand {
  background: url('images/panel_tools.png') no-repeat -32px -16px;
}
.panel-header,
.panel-body {
  border-color: #dfdfdf;
}
.panel-header {
  background-color: #f5f5f5;
  color: #404040;
}
.panel-body {
  background-color: #ffffff;
  color: #404040;
  font-size: 14px;
}
.panel-title {
  font-size: 14px;
  font-weight: bold;
  color: #404040;
  height: 20px;
  line-height: 20px;
}
.panel-footer {
  border: 1px solid #dfdfdf;
  overflow: hidden;
  background: #fafafa;
  color: #404040;
}
.panel-footer-noborder {
  border-width: 1px 0 0 0;
}
.panel-hleft,
.panel-hright {
  position: relative;
}
.panel-hleft>.panel-body,
.panel-hright>.panel-body {
  position: absolute;
}
.panel-hleft>.panel-header {
  float: left;
}
.panel-hright>.panel-header {
  float: right;
}
.panel-hleft>.panel-body {
  border-top-width: 1px;
  border-left-width: 0;
}
.panel-hright>.panel-body {
  border-top-width: 1px;
  border-right-width: 0;
}
.panel-hleft>.panel-body-nobottom {
  border-bottom-width: 1px;
  border-right-width: 0;
}
.panel-hright>.panel-body-nobottom {
  border-bottom-width: 1px;
  border-left-width: 0;
}
.panel-hleft>.panel-footer {
  position: absolute;
  right: 0;
}
.panel-hright>.panel-footer {
  position: absolute;
  left: 0;
}
.panel-hleft>.panel-header-noborder {
  border-width: 0 1px 0 0;
}
.panel-hright>.panel-header-noborder {
  border-width: 0 0 0 1px;
}
.panel-hleft>.panel-body-noborder {
  border-width: 0;
}
.panel-hright>.panel-body-noborder {
  border-width: 0;
}
.panel-hleft>.panel-body-noheader {
  border-left-width: 1px;
}
.panel-hright>.panel-body-noheader {
  border-right-width: 1px;
}
.panel-hleft>.panel-footer-noborder {
  border-width: 0 0 0 1px;
}
.panel-hright>.panel-footer-noborder {
  border-width: 0 1px 0 0;
}
.panel-hleft>.panel-header .panel-icon,
.panel-hright>.panel-header .panel-icon {
  margin-top: 0;
  top: 5px;
  left: 50%;
  margin-left: -8px;
}
.panel-hleft>.panel-header .panel-title,
.panel-hright>.panel-header .panel-title {
  position: absolute;
  min-width: 16px;
  left: 25px;
  top: 5px;
  bottom: auto;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform: rotate(90deg);
  -moz-transform-origin: 0 0;
  -o-transform: rotate(90deg);
  -o-transform-origin: 0 0;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.panel-hleft>.panel-header .panel-title-up,
.panel-hright>.panel-header .panel-title-up {
  position: absolute;
  min-width: 16px;
  left: 21px;
  top: auto;
  bottom: 0px;
  text-align: right;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform: rotate(-90deg);
  -moz-transform-origin: 0 0;
  -o-transform: rotate(-90deg);
  -o-transform-origin: 0 0;
  transform: rotate(-90deg);
  transform-origin: 0 16px;
}
.panel-hleft>.panel-header .panel-with-icon.panel-title-up,
.panel-hright>.panel-header .panel-with-icon.panel-title-up {
  padding-left: 0;
  padding-right: 18px;
}
.panel-hleft>.panel-header .panel-tool,
.panel-hright>.panel-header .panel-tool {
  top: auto;
  bottom: 5px;
  width: 16px;
  height: auto;
  left: 50%;
  margin-left: -8px;
  margin-top: 0;
}
.panel-hleft>.panel-header .panel-tool a,
.panel-hright>.panel-header .panel-tool a {
  margin: 2px 0 0 0;
}
.accordion {
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
}
.accordion .accordion-header {
  border-width: 0 0 1px;
  cursor: pointer;
}
.accordion .accordion-body {
  border-width: 0 0 1px;
}
.accordion-noborder {
  border-width: 0;
}
.accordion-noborder .accordion-header {
  border-width: 0 0 1px;
}
.accordion-noborder .accordion-body {
  border-width: 0 0 1px;
}
.accordion-collapse {
  background: url('images/accordion_arrows.png') no-repeat 0 0;
}
.accordion-expand {
  background: url('images/accordion_arrows.png') no-repeat -16px 0;
}
.accordion {
  background: #ffffff;
  border-color: #dfdfdf;
}
.accordion .accordion-header {
  background: #f5f5f5;
  filter: none;
}
.accordion .accordion-header-selected {
  background: #eee;
}
.accordion .accordion-header-selected .panel-title {
  color: #2196f3;
}
.accordion .panel-last > .accordion-header {
  border-bottom-color: #f5f5f5;
}
.accordion .panel-last > .accordion-body {
  border-bottom-color: #ffffff;
}
.accordion .panel-last > .accordion-header-selected,
.accordion .panel-last > .accordion-header-border {
  border-bottom-color: #dfdfdf;
}
.accordion> .panel-hleft {
  float: left;
}
.accordion> .panel-hleft>.panel-header {
  border-width: 0 1px 0 0;
}
.accordion> .panel-hleft> .panel-body {
  border-width: 0 1px 0 0;
}
.accordion> .panel-hleft.panel-last > .accordion-header {
  border-right-color: #f5f5f5;
}
.accordion> .panel-hleft.panel-last > .accordion-body {
  border-right-color: #ffffff;
}
.accordion> .panel-hleft.panel-last > .accordion-header-selected,
.accordion> .panel-hleft.panel-last > .accordion-header-border {
  border-right-color: #dfdfdf;
}
.accordion> .panel-hright {
  float: right;
}
.accordion> .panel-hright>.panel-header {
  border-width: 0 0 0 1px;
}
.accordion> .panel-hright> .panel-body {
  border-width: 0 0 0 1px;
}
.accordion> .panel-hright.panel-last > .accordion-header {
  border-left-color: #f5f5f5;
}
.accordion> .panel-hright.panel-last > .accordion-body {
  border-left-color: #ffffff;
}
.accordion> .panel-hright.panel-last > .accordion-header-selected,
.accordion> .panel-hright.panel-last > .accordion-header-border {
  border-left-color: #dfdfdf;
}
.window {
  overflow: hidden;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
}
.window .window-header {
  background: transparent;
  padding: 0px 0px 6px 0px;
}
.window .window-body {
  border-width: 1px;
  border-style: solid;
  border-top-width: 0px;
}
.window .window-body-noheader {
  border-top-width: 1px;
}
.window .panel-body-nobottom {
  border-bottom-width: 0;
}
.window .window-header .panel-icon,
.window .window-header .panel-tool {
  top: 50%;
  margin-top: -11px;
}
.window .window-header .panel-icon {
  left: 1px;
}
.window .window-header .panel-tool {
  right: 1px;
}
.window .window-header .panel-with-icon {
  padding-left: 18px;
}
.window-proxy {
  position: absolute;
  overflow: hidden;
}
.window-proxy-mask {
  position: absolute;
  filter: alpha(opacity=5);
  opacity: 0.05;
}
.window-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=40);
  opacity: 0.40;
  font-size: 1px;
  overflow: hidden;
}
.window,
.window-shadow {
  position: absolute;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.window-shadow {
  background: #ccc;
  -moz-box-shadow: 2px 2px 3px #cccccc;
  -webkit-box-shadow: 2px 2px 3px #cccccc;
  box-shadow: 2px 2px 3px #cccccc;
  filter: progid:DXImageTransform.Microsoft.Blur(pixelRadius=2,MakeShadow=false,ShadowOpacity=0.2);
}
.window,
.window .window-body {
  border-color: #dfdfdf;
}
.window {
  background-color: #f5f5f5;
}
.window-proxy {
  border: 1px dashed #dfdfdf;
}
.window-proxy-mask,
.window-mask {
  background: #eee;
}
.window .panel-footer {
  border: 1px solid #dfdfdf;
  position: relative;
  top: -1px;
}
.window-thinborder {
  padding: 0;
}
.window-thinborder .window-header {
  padding: 5px 5px 6px 5px;
}
.window-thinborder .window-body {
  border-width: 0px;
}
.window-thinborder .window-footer {
  border-left: transparent;
  border-right: transparent;
  border-bottom: transparent;
}
.window-thinborder .window-header .panel-icon,
.window-thinborder .window-header .panel-tool {
  margin-top: -9px;
  margin-left: 5px;
  margin-right: 5px;
}
.window-noborder {
  border: 0;
}
.window.panel-hleft .window-header {
  padding: 0 6px 0 0;
}
.window.panel-hright .window-header {
  padding: 0 0 0 6px;
}
.window.panel-hleft>.panel-header .panel-title {
  top: auto;
  left: 16px;
}
.window.panel-hright>.panel-header .panel-title {
  top: auto;
  right: 16px;
}
.window.panel-hleft>.panel-header .panel-title-up,
.window.panel-hright>.panel-header .panel-title-up {
  bottom: 0;
}
.window.panel-hleft .window-body {
  border-width: 1px 1px 1px 0;
}
.window.panel-hright .window-body {
  border-width: 1px 0 1px 1px;
}
.window.panel-hleft .window-header .panel-icon {
  top: 1px;
  margin-top: 0;
  left: 0;
}
.window.panel-hright .window-header .panel-icon {
  top: 1px;
  margin-top: 0;
  left: auto;
  right: 1px;
}
.window.panel-hleft .window-header .panel-tool,
.window.panel-hright .window-header .panel-tool {
  margin-top: 0;
  top: auto;
  bottom: 1px;
  right: auto;
  margin-right: 0;
  left: 50%;
  margin-left: -11px;
}
.window.panel-hright .window-header .panel-tool {
  left: auto;
  right: 1px;
}
.window-thinborder.panel-hleft .window-header {
  padding: 5px 6px 5px 5px;
}
.window-thinborder.panel-hright .window-header {
  padding: 5px 5px 5px 6px;
}
.window-thinborder.panel-hleft>.panel-header .panel-title {
  left: 21px;
}
.window-thinborder.panel-hleft>.panel-header .panel-title-up,
.window-thinborder.panel-hright>.panel-header .panel-title-up {
  bottom: 5px;
}
.window-thinborder.panel-hleft .window-header .panel-icon,
.window-thinborder.panel-hright .window-header .panel-icon {
  margin-top: 5px;
}
.window-thinborder.panel-hleft .window-header .panel-tool,
.window-thinborder.panel-hright .window-header .panel-tool {
  left: 16px;
  bottom: 5px;
}
.dialog-content {
  overflow: auto;
}
.dialog-toolbar {
  position: relative;
  padding: 2px 5px;
}
.dialog-tool-separator {
  float: left;
  height: 24px;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #fff;
  margin: 2px 1px;
}
.dialog-button {
  position: relative;
  top: -1px;
  padding: 5px;
  text-align: right;
}
.dialog-button .l-btn {
  margin-left: 5px;
}
.dialog-toolbar,
.dialog-button {
  background: #fafafa;
  border-width: 1px;
  border-style: solid;
}
.dialog-toolbar {
  border-color: #dfdfdf #dfdfdf #dfdfdf #dfdfdf;
}
.dialog-button {
  border-color: #dfdfdf #dfdfdf #dfdfdf #dfdfdf;
}
.window-thinborder .dialog-toolbar {
  border-left: transparent;
  border-right: transparent;
  border-top-color: #fafafa;
}
.window-thinborder .dialog-button {
  top: 0px;
  padding: 5px 8px 8px 8px;
  border-left: transparent;
  border-right: transparent;
  border-bottom: transparent;
}
.l-btn {
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
}
.l-btn-plain {
  border-width: 0;
  padding: 1px;
}
.l-btn-left {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.l-btn-text {
  display: inline-block;
  vertical-align: top;
  width: auto;
  line-height: 28px;
  font-size: 14px;
  padding: 0;
  margin: 0 6px;
}
.l-btn-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  font-size: 1px;
}
.l-btn span span .l-btn-empty {
  display: inline-block;
  margin: 0;
  width: 16px;
  height: 24px;
  font-size: 1px;
  vertical-align: top;
}
.l-btn span .l-btn-icon-left {
  padding: 0 0 0 20px;
  background-position: left center;
}
.l-btn span .l-btn-icon-right {
  padding: 0 20px 0 0;
  background-position: right center;
}
.l-btn-icon-left .l-btn-text {
  margin: 0 6px 0 26px;
}
.l-btn-icon-left .l-btn-icon {
  left: 6px;
}
.l-btn-icon-right .l-btn-text {
  margin: 0 26px 0 6px;
}
.l-btn-icon-right .l-btn-icon {
  right: 6px;
}
.l-btn-icon-top .l-btn-text {
  margin: 20px 4px 0 4px;
}
.l-btn-icon-top .l-btn-icon {
  top: 4px;
  left: 50%;
  margin: 0 0 0 -8px;
}
.l-btn-icon-bottom .l-btn-text {
  margin: 0 4px 20px 4px;
}
.l-btn-icon-bottom .l-btn-icon {
  top: auto;
  bottom: 4px;
  left: 50%;
  margin: 0 0 0 -8px;
}
.l-btn-left .l-btn-empty {
  margin: 0 6px;
  width: 16px;
}
.l-btn-plain:hover {
  padding: 0;
}
.l-btn-focus {
  outline: #0000FF dotted thin;
}
.l-btn-large .l-btn-text {
  line-height: 44px;
}
.l-btn-large .l-btn-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-top: -16px;
}
.l-btn-large .l-btn-icon-left .l-btn-text {
  margin-left: 40px;
}
.l-btn-large .l-btn-icon-right .l-btn-text {
  margin-right: 40px;
}
.l-btn-large .l-btn-icon-top .l-btn-text {
  margin-top: 36px;
  line-height: 24px;
  min-width: 32px;
}
.l-btn-large .l-btn-icon-top .l-btn-icon {
  margin: 0 0 0 -16px;
}
.l-btn-large .l-btn-icon-bottom .l-btn-text {
  margin-bottom: 36px;
  line-height: 24px;
  min-width: 32px;
}
.l-btn-large .l-btn-icon-bottom .l-btn-icon {
  margin: 0 0 0 -16px;
}
.l-btn-large .l-btn-left .l-btn-empty {
  margin: 0 6px;
  width: 32px;
}
.l-btn {
  color: #ffffff;
  background: #2196f3;
  background-repeat: repeat-x;
  border: 1px solid #2196f3;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.l-btn:hover {
  background: #eee;
  color: #404040;
  border: 1px solid #ccc;
  filter: none;
}
.l-btn-plain {
  background: transparent;
  border-width: 0;
  filter: none;
}
.l-btn-outline {
  border-width: 1px;
  border-color: #ccc;
  padding: 0;
}
.l-btn-plain:hover {
  background: #eee;
  color: #404040;
  border: 1px solid #ccc;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.l-btn-disabled,
.l-btn-disabled:hover {
  opacity: 0.5;
  cursor: default;
  background: #2196f3;
  color: #ffffff;
}
.l-btn-disabled .l-btn-text,
.l-btn-disabled .l-btn-icon {
  filter: alpha(opacity=50);
}
.l-btn-plain-disabled,
.l-btn-plain-disabled:hover {
  background: transparent;
  filter: alpha(opacity=50);
}
.l-btn-selected,
.l-btn-selected:hover {
  background: #6ab8f7;
  filter: none;
}
.l-btn-plain-selected,
.l-btn-plain-selected:hover {
  background: #6ab8f7;
}
.textbox {
  position: relative;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.textbox .textbox-text {
  font-size: 14px;
  border: 0;
  margin: 0;
  padding: 0 4px;
  white-space: normal;
  vertical-align: top;
  outline-style: none;
  resize: none;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  height: 28px;
  line-height: 28px;
}
.textbox textarea.textbox-text {
  line-height: normal;
}
.textbox .textbox-text::-ms-clear,
.textbox .textbox-text::-ms-reveal {
  display: none;
}
.textbox textarea.textbox-text {
  white-space: pre-wrap;
}
.textbox .textbox-prompt {
  font-size: 14px;
  color: #aaa;
}
.textbox .textbox-bgicon {
  background-position: 3px center;
  padding-left: 21px;
}
.textbox .textbox-button,
.textbox .textbox-button:hover {
  position: absolute;
  top: 0;
  padding: 0;
  vertical-align: top;
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.textbox .textbox-button-right,
.textbox .textbox-button-right:hover {
  right: 0;
  border-width: 0 0 0 1px;
}
.textbox .textbox-button-left,
.textbox .textbox-button-left:hover {
  left: 0;
  border-width: 0 1px 0 0;
}
.textbox .textbox-button-top,
.textbox .textbox-button-top:hover {
  left: 0;
  border-width: 0 0 1px 0;
}
.textbox .textbox-button-bottom,
.textbox .textbox-button-bottom:hover {
  top: auto;
  bottom: 0;
  left: 0;
  border-width: 1px 0 0 0;
}
.textbox-addon {
  position: absolute;
  top: 0;
}
.textbox-label {
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  padding-right: 5px;
}
.textbox-label-after {
  padding-left: 5px;
  padding-right: 0;
}
.textbox-label-top {
  display: block;
  width: auto;
  padding: 0;
}
.textbox-disabled,
.textbox-label-disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.textbox-icon {
  display: inline-block;
  width: 18px;
  height: 20px;
  overflow: hidden;
  vertical-align: top;
  background-position: center center;
  cursor: pointer;
  opacity: 0.6;
  filter: alpha(opacity=60);
  text-decoration: none;
  outline-style: none;
}
.textbox-icon-disabled,
.textbox-icon-readonly {
  cursor: default;
}
.textbox-icon:hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
}
.textbox-icon-disabled:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.textbox-focused {
  border-color: #c6c6c6;
  -moz-box-shadow: 0 0 3px 0 #dfdfdf;
  -webkit-box-shadow: 0 0 3px 0 #dfdfdf;
  box-shadow: 0 0 3px 0 #dfdfdf;
}
.textbox-invalid {
  border-color: #c62828;
  background-color: #fff;
}
.form-floating-label.form-field .textbox-text {
  padding: 0;
}
.form-floating-label.form-field .textbox-label {
  position: relative;
  height: 20px;
  line-height: 20px;
  transition: all .3s;
  font-size: 12px;
  z-index: 9;
}
.form-floating-label.form-field-empty .textbox-label {
  cursor: text;
  font-size: 14px;
  transform: translate(0,25px);
}
.form-floating-label.form-field-empty.form-field-focused .textbox-label {
  cursor: default;
  font-size: 12px;
  transform: translate(0,0);
}
.passwordbox-open {
  background: url('images/passwordbox_open.png') no-repeat center center;
}
.passwordbox-close {
  background: url('images/passwordbox_close.png') no-repeat center center;
}
.filebox .textbox-value {
  vertical-align: top;
  position: absolute;
  top: 0;
  left: -5000px;
}
.filebox-label {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 10;
  background: url('images/blank.gif') no-repeat;
}
.l-btn-disabled .filebox-label {
  cursor: default;
}
.combo-arrow {
  width: 18px;
  height: 20px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.combo-arrow-hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
}
.combo-panel {
  overflow: auto;
}
.combo-arrow {
  background: url('images/combo_arrow.png') no-repeat center center;
}
.combo-panel {
  background-color: #ffffff;
}
.combo-arrow {
  background-color: #f5f5f5;
}
.combo-arrow-hover {
  background-color: #eee;
}
.combo-arrow:hover {
  background-color: #eee;
}
.combo .textbox-icon-disabled:hover {
  cursor: default;
}
.combobox-item,
.combobox-group,
.combobox-stick {
  font-size: 14px;
  padding: 6px 4px;
  line-height: 20px;
}
.combobox-item-disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.combobox-gitem {
  padding-left: 10px;
}
.combobox-group,
.combobox-stick {
  font-weight: bold;
}
.combobox-stick {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  background: inherit;
}
.combobox-item-hover {
  background-color: #eee;
  color: #404040;
}
.combobox-item-selected {
  background-color: #eee;
  color: #2196f3;
}
.combobox-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 2px;
}
.tagbox {
  cursor: text;
}
.tagbox .textbox-text {
  float: left;
}
.tagbox-label {
  position: relative;
  display: block;
  margin: 4px 0 0 4px;
  padding: 0 20px 0 4px;
  float: left;
  vertical-align: top;
  text-decoration: none;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  background: #eee;
  color: #404040;
}
.tagbox-remove {
  background: url('images/tagbox_icons.png') no-repeat -16px center;
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  right: 2px;
  top: 50%;
  margin-top: -8px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.tagbox-remove:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.textbox-disabled .tagbox-label {
  cursor: default;
}
.textbox-disabled .tagbox-remove:hover {
  cursor: default;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.layout {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  z-index: 0;
}
.layout-panel {
  position: absolute;
  overflow: hidden;
}
.layout-body {
  min-width: 1px;
  min-height: 1px;
}
.layout-panel-east,
.layout-panel-west {
  z-index: 2;
}
.layout-panel-north,
.layout-panel-south {
  z-index: 3;
}
.layout-expand {
  position: absolute;
  padding: 0px;
  font-size: 1px;
  cursor: pointer;
  z-index: 1;
}
.layout-expand .panel-header,
.layout-expand .panel-body {
  background: transparent;
  filter: none;
  overflow: hidden;
}
.layout-expand .panel-header {
  border-bottom-width: 0px;
}
.layout-expand .panel-body {
  position: relative;
}
.layout-expand .panel-body .panel-icon {
  margin-top: 0;
  top: 0;
  left: 50%;
  margin-left: -8px;
}
.layout-expand-west .panel-header .panel-icon,
.layout-expand-east .panel-header .panel-icon {
  display: none;
}
.layout-expand-title {
  position: absolute;
  top: 0;
  left: 21px;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform: rotate(90deg);
  -moz-transform-origin: 0 0;
  -o-transform: rotate(90deg);
  -o-transform-origin: 0 0;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.layout-expand-title-up {
  position: absolute;
  top: 0;
  left: 0;
  text-align: right;
  padding-left: 5px;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform: rotate(-90deg);
  -moz-transform-origin: 0 0;
  -o-transform: rotate(-90deg);
  -o-transform-origin: 0 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
}
.layout-expand-with-icon {
  top: 18px;
}
.layout-expand .panel-body-noheader .layout-expand-title,
.layout-expand .panel-body-noheader .panel-icon {
  top: 5px;
}
.layout-expand .panel-body-noheader .layout-expand-with-icon {
  top: 23px;
}
.layout-split-proxy-h,
.layout-split-proxy-v {
  position: absolute;
  font-size: 1px;
  display: none;
  z-index: 5;
}
.layout-split-proxy-h {
  width: 5px;
  cursor: e-resize;
}
.layout-split-proxy-v {
  height: 5px;
  cursor: n-resize;
}
.layout-mask {
  position: absolute;
  background: #fafafa;
  filter: alpha(opacity=10);
  opacity: 0.10;
  z-index: 4;
}
.layout-button-up {
  background: url('images/layout_arrows.png') no-repeat -16px -16px;
}
.layout-button-down {
  background: url('images/layout_arrows.png') no-repeat -16px 0;
}
.layout-button-left {
  background: url('images/layout_arrows.png') no-repeat 0 0;
}
.layout-button-right {
  background: url('images/layout_arrows.png') no-repeat 0 -16px;
}
.layout-split-proxy-h,
.layout-split-proxy-v {
  background-color: #ccc;
}
.layout-split-north {
  border-bottom: 5px solid #ffffff;
}
.layout-split-south {
  border-top: 5px solid #ffffff;
}
.layout-split-east {
  border-left: 5px solid #ffffff;
}
.layout-split-west {
  border-right: 5px solid #ffffff;
}
.layout-expand {
  background-color: #f5f5f5;
}
.layout-expand-over {
  background-color: #f5f5f5;
}
.tabs-container {
  overflow: hidden;
}
.tabs-header {
  border-width: 1px;
  border-style: solid;
  border-bottom-width: 0;
  position: relative;
  padding: 0;
  padding-top: 2px;
  overflow: hidden;
}
.tabs-scroller-left,
.tabs-scroller-right {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 18px;
  font-size: 1px;
  display: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
}
.tabs-scroller-left {
  left: 0;
}
.tabs-scroller-right {
  right: 0;
}
.tabs-tool {
  position: absolute;
  bottom: 0;
  padding: 1px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
}
.tabs-header-plain .tabs-tool {
  padding: 0 1px;
}
.tabs-wrap {
  position: relative;
  left: 0;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}
.tabs-scrolling {
  margin-left: 18px;
  margin-right: 18px;
}
.tabs-disabled {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.tabs {
  list-style-type: none;
  height: 26px;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
  width: 50000px;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.tabs li {
  float: left;
  display: inline-block;
  margin: 0 4px -1px 0;
  padding: 0;
  position: relative;
  border: 0;
}
.tabs li .tabs-inner {
  display: inline-block;
  text-decoration: none;
  cursor: hand;
  cursor: pointer;
  margin: 0;
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.tabs li.tabs-selected .tabs-inner {
  font-weight: bold;
  outline: none;
}
.tabs li.tabs-selected .tabs-inner:hover {
  cursor: default;
  pointer: default;
}
.tabs li a.tabs-close,
.tabs-p-tool {
  position: absolute;
  font-size: 1px;
  display: block;
  height: 12px;
  padding: 0;
  top: 50%;
  margin-top: -6px;
  overflow: hidden;
}
.tabs li a.tabs-close {
  width: 12px;
  right: 5px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.tabs-p-tool {
  right: 16px;
}
.tabs-p-tool a {
  display: inline-block;
  font-size: 1px;
  width: 12px;
  height: 12px;
  margin: 0;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.tabs li a:hover.tabs-close,
.tabs-p-tool a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  cursor: hand;
  cursor: pointer;
}
.tabs-with-icon {
  padding-left: 18px;
}
.tabs-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 10px;
  top: 50%;
  margin-top: -8px;
}
.tabs-title {
  font-size: 14px;
}
.tabs-closable {
  padding-right: 8px;
}
.tabs-panels {
  margin: 0px;
  padding: 0px;
  border-width: 1px;
  border-style: solid;
  border-top-width: 0;
  overflow: hidden;
}
.tabs-header-bottom {
  border-width: 0 1px 1px 1px;
  padding: 0 0 2px 0;
}
.tabs-header-bottom .tabs {
  border-width: 1px 0 0 0;
}
.tabs-header-bottom .tabs li {
  margin: -1px 4px 0 0;
}
.tabs-header-bottom .tabs li .tabs-inner {
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}
.tabs-header-bottom .tabs-tool {
  top: 0;
}
.tabs-header-bottom .tabs-scroller-left,
.tabs-header-bottom .tabs-scroller-right {
  top: 0;
  bottom: auto;
}
.tabs-panels-top {
  border-width: 1px 1px 0 1px;
}
.tabs-header-left {
  float: left;
  border-width: 1px 0 1px 1px;
  padding: 0;
}
.tabs-header-right {
  float: right;
  border-width: 1px 1px 1px 0;
  padding: 0;
}
.tabs-header-left .tabs-wrap,
.tabs-header-right .tabs-wrap {
  height: 100%;
}
.tabs-header-left .tabs {
  height: 100%;
  padding: 4px 0 0 2px;
  border-width: 0 1px 0 0;
}
.tabs-header-right .tabs {
  height: 100%;
  padding: 4px 2px 0 0;
  border-width: 0 0 0 1px;
}
.tabs-header-left .tabs li,
.tabs-header-right .tabs li {
  display: block;
  width: 100%;
  position: relative;
}
.tabs-header-left .tabs li {
  left: auto;
  right: 0;
  margin: 0 -1px 4px 0;
  float: right;
}
.tabs-header-right .tabs li {
  left: 0;
  right: auto;
  margin: 0 0 4px -1px;
  float: left;
}
.tabs-justified li .tabs-inner {
  padding-left: 0;
  padding-right: 0;
}
.tabs-header-left .tabs li .tabs-inner {
  display: block;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  -moz-border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}
.tabs-header-right .tabs li .tabs-inner {
  display: block;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.tabs-panels-right {
  float: right;
  border-width: 1px 1px 1px 0;
}
.tabs-panels-left {
  float: left;
  border-width: 1px 0 1px 1px;
}
.tabs-header-noborder,
.tabs-panels-noborder {
  border: 0px;
}
.tabs-header-plain {
  border: 0px;
  background: transparent;
}
.tabs-pill {
  padding-bottom: 3px;
}
.tabs-header-bottom .tabs-pill {
  padding-top: 3px;
  padding-bottom: 0;
}
.tabs-header-left .tabs-pill {
  padding-right: 3px;
}
.tabs-header-right .tabs-pill {
  padding-left: 3px;
}
.tabs-header .tabs-pill li .tabs-inner {
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.tabs-header-narrow,
.tabs-header-narrow .tabs-narrow {
  padding: 0;
}
.tabs-narrow li,
.tabs-header-bottom .tabs-narrow li {
  margin-left: 0;
  margin-right: -1px;
}
.tabs-narrow li.tabs-last,
.tabs-header-bottom .tabs-narrow li.tabs-last {
  margin-right: 0;
}
.tabs-header-left .tabs-narrow,
.tabs-header-right .tabs-narrow {
  padding-top: 0;
}
.tabs-header-left .tabs-narrow li {
  margin-bottom: -1px;
  margin-right: -1px;
}
.tabs-header-left .tabs-narrow li.tabs-last,
.tabs-header-right .tabs-narrow li.tabs-last {
  margin-bottom: 0;
}
.tabs-header-right .tabs-narrow li {
  margin-bottom: -1px;
  margin-left: -1px;
}
.tabs-scroller-left {
  background: #f5f5f5 url('images/tabs_icons.png') no-repeat 1px center;
}
.tabs-scroller-right {
  background: #f5f5f5 url('images/tabs_icons.png') no-repeat -15px center;
}
.tabs li a.tabs-close {
  background: url('images/tabs_icons.png') no-repeat -34px center;
}
.tabs li .tabs-inner:hover {
  background: #eee;
  color: #404040;
  filter: none;
}
.tabs li.tabs-selected .tabs-inner {
  background-color: #ffffff;
  color: #404040;
}
.tabs li .tabs-inner {
  color: #404040;
  background-color: #f5f5f5;
}
.tabs-header,
.tabs-tool {
  background-color: #f5f5f5;
}
.tabs-header-plain {
  background: transparent;
}
.tabs-header,
.tabs-scroller-left,
.tabs-scroller-right,
.tabs-tool,
.tabs,
.tabs-panels,
.tabs li .tabs-inner,
.tabs li.tabs-selected .tabs-inner,
.tabs-header-bottom .tabs li.tabs-selected .tabs-inner,
.tabs-header-left .tabs li.tabs-selected .tabs-inner,
.tabs-header-right .tabs li.tabs-selected .tabs-inner {
  border-color: #dfdfdf;
}
.tabs-p-tool a:hover,
.tabs li a:hover.tabs-close,
.tabs-scroller-over {
  background-color: #eee;
}
.tabs li.tabs-selected .tabs-inner {
  border-bottom: 1px solid #ffffff;
}
.tabs-header-bottom .tabs li.tabs-selected .tabs-inner {
  border-top: 1px solid #ffffff;
}
.tabs-header-left .tabs li.tabs-selected .tabs-inner {
  border-right: 1px solid #ffffff;
}
.tabs-header-right .tabs li.tabs-selected .tabs-inner {
  border-left: 1px solid #ffffff;
}
.tabs-header .tabs-pill li.tabs-selected .tabs-inner {
  background: #eee;
  color: #2196f3;
  filter: none;
  border-color: #dfdfdf;
}
.datagrid .panel-body {
  overflow: hidden;
  position: relative;
}
.datagrid-view {
  position: relative;
  overflow: hidden;
}
.datagrid-view1,
.datagrid-view2 {
  position: absolute;
  overflow: hidden;
  top: 0;
}
.datagrid-view1 {
  left: 0;
}
.datagrid-view2 {
  right: 0;
}
.datagrid-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  filter: alpha(opacity=30);
  display: none;
}
.datagrid-mask-msg {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  padding: 10px 5px 10px 30px;
  width: auto;
  height: 16px;
  border-width: 2px;
  border-style: solid;
  display: none;
}
.datagrid-empty {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
.datagrid-sort-icon {
  padding: 0;
  display: none;
}
.datagrid-toolbar {
  height: auto;
  padding: 1px 2px;
  border-width: 0 0 1px 0;
  border-style: solid;
}
.datagrid-btn-separator {
  float: left;
  height: 24px;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #fff;
  margin: 2px 1px;
}
.datagrid .datagrid-pager {
  display: block;
  margin: 0;
  border-width: 1px 0 0 0;
  border-style: solid;
}
.datagrid .datagrid-pager-top {
  border-width: 0 0 1px 0;
}
.datagrid-header {
  overflow: hidden;
  cursor: default;
  border-width: 0 0 1px 0;
  border-style: solid;
}
.datagrid-header-inner {
  float: left;
  width: 10000px;
}
.datagrid-header-row,
.datagrid-row {
  height: 32px;
}
.datagrid-header td,
.datagrid-body td,
.datagrid-footer td {
  border-width: 0 1px 1px 0;
  border-style: dotted;
  margin: 0;
  padding: 0;
}
.datagrid-cell,
.datagrid-cell-group,
.datagrid-header-rownumber,
.datagrid-cell-rownumber {
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
}
.datagrid-header .datagrid-cell {
  height: auto;
}
.datagrid-header .datagrid-cell span {
  font-size: 14px;
}
.datagrid-cell-group {
  text-align: center;
  text-overflow: ellipsis;
}
.datagrid-header-rownumber,
.datagrid-cell-rownumber {
  width: 30px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.datagrid-body {
  margin: 0;
  padding: 0;
  overflow: auto;
  zoom: 1;
}
.datagrid-view1 .datagrid-body-inner {
  padding-bottom: 20px;
}
.datagrid-view1 .datagrid-body {
  overflow: hidden;
}
.datagrid-footer {
  overflow: hidden;
}
.datagrid-footer-inner {
  border-width: 1px 0 0 0;
  border-style: solid;
  width: 10000px;
  float: left;
}
.datagrid-row-editing .datagrid-cell {
  height: auto;
}
.datagrid-header-check,
.datagrid-cell-check {
  padding: 0;
  width: 27px;
  height: 18px;
  font-size: 1px;
  text-align: center;
  overflow: hidden;
}
.datagrid-header-check input,
.datagrid-cell-check input {
  margin: 0;
  padding: 0;
  width: 15px;
  height: 18px;
}
.datagrid-resize-proxy {
  position: absolute;
  width: 1px;
  height: 10000px;
  top: 0;
  cursor: e-resize;
  display: none;
}
.datagrid-body .datagrid-editable {
  margin: 0;
  padding: 0;
}
.datagrid-body .datagrid-editable table {
  width: 100%;
  height: 100%;
}
.datagrid-body .datagrid-editable td {
  border: 0;
  margin: 0;
  padding: 0;
}
.datagrid-view .datagrid-editable-input {
  margin: 0;
  padding: 2px 4px;
  border: 1px solid #dfdfdf;
  font-size: 14px;
  outline-style: none;
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.datagrid-view .validatebox-invalid {
  border-color: #c62828;
}
.datagrid-sort .datagrid-sort-icon {
  display: inline;
  padding: 0 13px 0 0;
  background: url('images/datagrid_icons.png') no-repeat -64px center;
}
.datagrid-sort-desc .datagrid-sort-icon {
  display: inline;
  padding: 0 13px 0 0;
  background: url('images/datagrid_icons.png') no-repeat -16px center;
}
.datagrid-sort-asc .datagrid-sort-icon {
  display: inline;
  padding: 0 13px 0 0;
  background: url('images/datagrid_icons.png') no-repeat 0px center;
}
.datagrid-row-collapse {
  background: url('images/datagrid_icons.png') no-repeat -48px center;
}
.datagrid-row-expand {
  background: url('images/datagrid_icons.png') no-repeat -32px center;
}
.datagrid-mask-msg {
  background: #ffffff url('images/loading.gif') no-repeat scroll 5px center;
}
.datagrid-header,
.datagrid-td-rownumber {
  background-color: #fff;
}
.datagrid-cell-rownumber {
  color: #404040;
}
.datagrid-resize-proxy {
  background: #ccc;
}
.datagrid-mask {
  background: #eee;
}
.datagrid-mask-msg {
  border-color: #dfdfdf;
}
.datagrid-toolbar,
.datagrid-pager {
  background: #fafafa;
}
.datagrid-header,
.datagrid-toolbar,
.datagrid-pager,
.datagrid-footer-inner {
  border-color: #dfdfdf;
}
.datagrid-header td,
.datagrid-body td,
.datagrid-footer td {
  border-color: #dfdfdf;
}
.datagrid-htable,
.datagrid-btable,
.datagrid-ftable {
  color: #404040;
  border-collapse: separate;
}
.datagrid-row-alt {
  background: #fafafa;
}
.datagrid-row-over,
.datagrid-header td.datagrid-header-over {
  background: #eee;
  color: #404040;
  cursor: default;
}
.datagrid-row-selected {
  background: #eee;
  color: #2196f3;
}
.datagrid-row-editing .textbox,
.datagrid-row-editing .textbox-text {
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.datagrid-header .datagrid-filter-row td.datagrid-header-over {
  background: inherit;
}
.datagrid-split-proxy {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  border-left: 1px solid #2196f3;
}
.datagrid-moving-proxy {
  border: 1px solid #2196f3;
  height: 32px;
  line-height: 32px;
  padding: 0 4px;
}
.propertygrid .datagrid-view1 .datagrid-body td {
  padding-bottom: 1px;
  border-width: 0 1px 0 0;
}
.propertygrid .datagrid-group {
  overflow: hidden;
  border-width: 0 0 1px 0;
  border-style: solid;
}
.propertygrid .datagrid-group span {
  font-weight: bold;
}
.propertygrid .datagrid-view1 .datagrid-body td {
  border-color: #dfdfdf;
}
.propertygrid .datagrid-view1 .datagrid-group {
  border-color: #f5f5f5;
}
.propertygrid .datagrid-view2 .datagrid-group {
  border-color: #dfdfdf;
}
.propertygrid .datagrid-group,
.propertygrid .datagrid-view1 .datagrid-body,
.propertygrid .datagrid-view1 .datagrid-row-over,
.propertygrid .datagrid-view1 .datagrid-row-selected {
  background: #f5f5f5;
}
.datalist .datagrid-header {
  border-width: 0;
}
.datalist .datagrid-group,
.m-list .m-list-group {
  height: 25px;
  line-height: 25px;
  font-weight: bold;
  overflow: hidden;
  background-color: #fff;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #dfdfdf;
}
.datalist .datagrid-group-expander {
  display: none;
}
.datalist .datagrid-group-title {
  padding: 0 4px;
}
.datalist .datagrid-btable {
  width: 100%;
  table-layout: fixed;
}
.datalist .datagrid-row td {
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-width: 0;
}
.datalist-lines .datagrid-row td {
  border-bottom-width: 1px;
}
.datalist .datagrid-cell,
.m-list li {
  width: auto;
  height: auto;
  padding: 2px 4px;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.datalist-link,
.m-list li>a {
  display: block;
  position: relative;
  cursor: pointer;
  color: #404040;
  text-decoration: none;
  overflow: hidden;
  margin: -2px -4px;
  padding: 2px 4px;
  padding-right: 16px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.datalist-link::after,
.m-list li>a::after {
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  content: '';
  right: 6px;
  top: 50%;
  margin-top: -4px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.m-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.m-list li {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #dfdfdf;
}
.m-list li>a:hover {
  background: #eee;
  color: #404040;
}
.m-list .m-list-group {
  padding: 0 4px;
}
.pagination {
  zoom: 1;
  padding: 2px;
}
.pagination table {
  float: left;
  height: 30px;
}
.pagination td {
  border: 0;
}
.pagination-btn-separator {
  float: left;
  height: 24px;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #fff;
  margin: 3px 1px;
}
.pagination .pagination-num {
  border-width: 1px;
  border-style: solid;
  margin: 0 2px;
  padding: 2px;
  width: 3em;
  height: auto;
  text-align: center;
  font-size: 14px;
}
.pagination-page-list {
  margin: 0px 6px;
  padding: 1px 2px;
  width: auto;
  height: auto;
  border-width: 1px;
  border-style: solid;
}
.pagination-info {
  float: right;
  margin: 0 6px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.pagination span {
  font-size: 14px;
}
.pagination-link .l-btn-text {
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  padding: 0 .5em;
  width: auto;
  min-width: 28px;
}
.pagination-first {
  background: url('images/pagination_icons.png') no-repeat 0 center;
}
.pagination-prev {
  background: url('images/pagination_icons.png') no-repeat -16px center;
}
.pagination-next {
  background: url('images/pagination_icons.png') no-repeat -32px center;
}
.pagination-last {
  background: url('images/pagination_icons.png') no-repeat -48px center;
}
.pagination-load {
  background: url('images/pagination_icons.png') no-repeat -64px center;
}
.pagination-loading {
  background: url('images/loading.gif') no-repeat center center;
}
.pagination-page-list,
.pagination .pagination-num {
  border-color: #dfdfdf;
}
.calendar {
  border-width: 1px;
  border-style: solid;
  padding: 1px;
  overflow: hidden;
}
.calendar table {
  table-layout: fixed;
  border-collapse: separate;
  font-size: 14px;
  width: 100%;
  height: 100%;
}
.calendar table td,
.calendar table th {
  font-size: 14px;
}
.calendar-noborder {
  border: 0;
}
.calendar-header {
  position: relative;
  height: 36px;
}
.calendar-title {
  text-align: center;
  height: 36px;
  line-height: 36px;
}
.calendar-title span {
  position: relative;
  display: inline-block;
  top: 0px;
  padding: 0 3px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  cursor: pointer;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.calendar-prevmonth,
.calendar-nextmonth,
.calendar-prevyear,
.calendar-nextyear {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  font-size: 1px;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.calendar-prevmonth {
  left: 30px;
  background: url('images/calendar_arrows.png') no-repeat -16px 0;
}
.calendar-nextmonth {
  right: 30px;
  background: url('images/calendar_arrows.png') no-repeat -32px 0;
}
.calendar-prevyear {
  left: 10px;
  background: url('images/calendar_arrows.png') no-repeat 0px 0;
}
.calendar-nextyear {
  right: 10px;
  background: url('images/calendar_arrows.png') no-repeat -48px 0;
}
.calendar-body {
  position: relative;
}
.calendar-body th,
.calendar-body td {
  text-align: center;
}
.calendar-day {
  border: 0;
  padding: 1px;
  cursor: pointer;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.calendar-other-month {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.calendar-disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
  cursor: default;
}
.calendar-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 150px;
  padding: 5px;
  font-size: 14px;
  display: none;
  overflow: hidden;
}
.calendar-menu-year-inner {
  text-align: center;
  padding-bottom: 5px;
}
.calendar-menu-year {
  width: 80px;
  line-height: 26px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  outline-style: none;
  resize: none;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 14px;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.calendar-menu-prev,
.calendar-menu-next {
  display: inline-block;
  width: 25px;
  height: 28px;
  vertical-align: top;
  cursor: pointer;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.calendar-menu-prev {
  margin-right: 10px;
  background: url('images/calendar_arrows.png') no-repeat 5px center;
}
.calendar-menu-next {
  margin-left: 10px;
  background: url('images/calendar_arrows.png') no-repeat -44px center;
}
.calendar-menu-month {
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.calendar-body th,
.calendar-menu-month {
  color: #8d8d8d;
}
.calendar-day {
  color: #404040;
}
.calendar-sunday {
  color: #CC2222;
}
.calendar-saturday {
  color: #00ee00;
}
.calendar-today {
  color: #0000ff;
}
.calendar-menu-year {
  border-color: #dfdfdf;
}
.calendar {
  border-color: #dfdfdf;
}
.calendar-header {
  background: #f5f5f5;
}
.calendar-body,
.calendar-menu {
  background: #ffffff;
}
.calendar-body th {
  background: #fafafa;
  padding: 4px 0;
}
.calendar-hover,
.calendar-nav-hover,
.calendar-menu-hover {
  background-color: #eee;
  color: #404040;
}
.calendar-hover {
  border: 1px solid #ccc;
  padding: 0;
}
.calendar-selected {
  background-color: #eee;
  color: #2196f3;
  border: 1px solid #2196f3;
  padding: 0;
}
.calendar-info {
  background-color: #f5f5f5;
  font-size: 28px;
  height: 70px;
  padding: 10px 20px;
}
.calendar-info .year {
  font-size: 16px;
}
.datebox-calendar-inner {
  height: 250px;
}
.datebox-button {
  padding: 4px 0;
  text-align: center;
}
.datebox-button a {
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.datebox-button a:hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
}
.datebox-current,
.datebox-close {
  float: left;
}
.datebox-close {
  float: right;
}
.datebox .combo-arrow {
  background-image: url('images/datebox_arrow.png');
  background-position: center center;
}
.datebox-button {
  background-color: #fafafa;
}
.datebox-button a {
  color: #ffffff;
}
.spinner-arrow {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  margin: 0;
  padding: 0;
  opacity: 1.0;
  filter: alpha(opacity=100);
  width: 18px;
}
.spinner-arrow.spinner-button-top,
.spinner-arrow.spinner-button-bottom,
.spinner-arrow.spinner-button-left,
.spinner-arrow.spinner-button-right {
  background-color: #f5f5f5;
}
.spinner-arrow-up,
.spinner-arrow-down {
  opacity: 0.6;
  filter: alpha(opacity=60);
  display: block;
  font-size: 1px;
  width: 18px;
  height: 10px;
  width: 100%;
  height: 50%;
  color: #ffffff;
  outline-style: none;
  background-color: #f5f5f5;
}
.spinner-button-updown {
  opacity: 1.0;
}
.spinner-button-updown .spinner-button-top,
.spinner-button-updown .spinner-button-bottom {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
}
.spinner-button-updown .spinner-arrow-up,
.spinner-button-updown .spinner-arrow-down {
  opacity: 1.0;
  filter: alpha(opacity=100);
  cursor: pointer;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  position: absolute;
}
.spinner-button-updown .spinner-button-top,
.spinner-button-updown .spinner-button-bottom {
  cursor: pointer;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.spinner-button-updown .spinner-button-top:hover,
.spinner-button-updown .spinner-button-bottom:hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
}
.spinner-button-updown .spinner-arrow-up,
.spinner-button-updown .spinner-arrow-down,
.spinner-button-updown .spinner-arrow-up:hover,
.spinner-button-updown .spinner-arrow-down:hover {
  background-color: transparent;
}
.spinner-arrow-hover {
  background-color: #eee;
  opacity: 1.0;
  filter: alpha(opacity=100);
}
.spinner-button-top:hover,
.spinner-button-bottom:hover,
.spinner-button-left:hover,
.spinner-button-right:hover,
.spinner-arrow-up:hover,
.spinner-arrow-down:hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
  background-color: #eee;
}
.textbox-disabled .spinner-button-top:hover,
.textbox-disabled .spinner-button-bottom:hover,
.textbox-disabled .spinner-button-left:hover,
.textbox-disabled .spinner-button-right:hover,
.textbox-icon-disabled .spinner-arrow-up:hover,
.textbox-icon-disabled .spinner-arrow-down:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
  background-color: #f5f5f5;
  cursor: default;
}
.spinner .textbox-icon-disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.spinner-arrow-up {
  background: url('images/spinner_arrows.png') no-repeat 1px center;
  background-color: #f5f5f5;
}
.spinner-arrow-down {
  background: url('images/spinner_arrows.png') no-repeat -15px center;
  background-color: #f5f5f5;
}
.spinner-button-up {
  background: url('images/spinner_arrows.png') no-repeat -32px center;
}
.spinner-button-down {
  background: url('images/spinner_arrows.png') no-repeat -48px center;
}
.progressbar {
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  overflow: hidden;
  position: relative;
}
.progressbar-text {
  text-align: center;
  position: absolute;
}
.progressbar-value {
  position: relative;
  overflow: hidden;
  width: 0;
  -moz-border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}
.progressbar {
  border-color: #dfdfdf;
}
.progressbar-text {
  color: #404040;
  font-size: 14px;
}
.progressbar-value,
.progressbar-value .progressbar-text {
  background-color: #eee;
  color: #2196f3;
}
.searchbox-button {
  width: 18px;
  height: 20px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.searchbox-button-hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
}
.searchbox .l-btn-plain {
  border: 0;
  padding: 0;
  vertical-align: top;
  opacity: 0.6;
  filter: alpha(opacity=60);
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.searchbox .l-btn-plain:hover {
  border: 0;
  padding: 0;
  opacity: 1.0;
  filter: alpha(opacity=100);
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.searchbox a.m-btn-plain-active {
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.searchbox .m-btn-active {
  border-width: 0 1px 0 0;
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
.searchbox .textbox-button-right {
  border-width: 0 0 0 1px;
}
.searchbox .textbox-button-left {
  border-width: 0 1px 0 0;
}
.searchbox-button {
  background: url('images/searchbox_button.png') no-repeat center center;
}
.searchbox .l-btn-plain {
  background: #f5f5f5;
}
.searchbox .l-btn-plain-disabled,
.searchbox .l-btn-plain-disabled:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.slider-disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.slider-h {
  height: 22px;
}
.slider-v {
  width: 22px;
}
.slider-inner {
  position: relative;
  height: 6px;
  top: 7px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
}
.slider-handle {
  position: absolute;
  display: block;
  outline: none;
  width: 20px;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  margin-left: -10px;
}
.slider-tip {
  position: absolute;
  display: inline-block;
  line-height: 12px;
  font-size: 14px;
  white-space: nowrap;
  top: -22px;
}
.slider-rule {
  position: relative;
  top: 15px;
}
.slider-rule span {
  position: absolute;
  display: inline-block;
  font-size: 0;
  height: 5px;
  border-width: 0 0 0 1px;
  border-style: solid;
}
.slider-rulelabel {
  position: relative;
  top: 20px;
}
.slider-rulelabel span {
  position: absolute;
  display: inline-block;
  font-size: 14px;
}
.slider-v .slider-inner {
  width: 6px;
  left: 7px;
  top: 0;
  float: left;
}
.slider-v .slider-handle {
  left: 50%;
  margin-top: -10px;
}
.slider-v .slider-tip {
  left: -10px;
  margin-top: -6px;
}
.slider-v .slider-rule {
  float: left;
  top: 0;
  left: 16px;
}
.slider-v .slider-rule span {
  width: 5px;
  height: 'auto';
  border-left: 0;
  border-width: 1px 0 0 0;
  border-style: solid;
}
.slider-v .slider-rulelabel {
  float: left;
  top: 0;
  left: 23px;
}
.slider-handle {
  background: url('images/slider_handle.png') no-repeat;
}
.slider-inner {
  border-color: #dfdfdf;
  background: #f5f5f5;
}
.slider-rule span {
  border-color: #dfdfdf;
}
.slider-rulelabel span {
  color: #404040;
}
.menu {
  position: absolute;
  margin: 0;
  padding: 2px;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
}
.menu-inline {
  position: relative;
}
.menu-item {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
}
.menu-text {
  height: 20px;
  line-height: 20px;
  float: left;
  padding-left: 28px;
}
.menu-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 2px;
  top: 50%;
  margin-top: -8px;
}
.menu-rightarrow {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0;
  top: 50%;
  margin-top: -8px;
}
.menu-line {
  position: absolute;
  left: 26px;
  top: 0;
  height: 2000px;
  font-size: 1px;
}
.menu-sep {
  margin: 3px 0px 3px 25px;
  font-size: 1px;
}
.menu-noline .menu-line {
  display: none;
}
.menu-noline .menu-sep {
  margin-left: 0;
  margin-right: 0;
}
.menu-active {
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.menu-item-disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default;
}
.menu-text,
.menu-text span {
  font-size: 14px;
}
.menu-shadow {
  position: absolute;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  background: #ccc;
  -moz-box-shadow: 2px 2px 3px #cccccc;
  -webkit-box-shadow: 2px 2px 3px #cccccc;
  box-shadow: 2px 2px 3px #cccccc;
  filter: progid:DXImageTransform.Microsoft.Blur(pixelRadius=2,MakeShadow=false,ShadowOpacity=0.2);
}
.menu-rightarrow {
  background: url('images/menu_arrows.png') no-repeat -32px center;
}
.menu-line {
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #fff;
}
.menu-sep {
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #fff;
}
.menu {
  background-color: #fff;
  border-color: #eee;
  color: #404040;
}
.menu-content {
  background: #ffffff;
}
.menu-item {
  border-color: transparent;
  _border-color: #fff;
}
.menu-active {
  border-color: #ccc;
  color: #404040;
  background: #eee;
}
.menu-active-disabled {
  border-color: transparent;
  background: transparent;
  color: #404040;
}
.m-btn-downarrow,
.s-btn-downarrow {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  font-size: 1px;
  right: 0;
  top: 50%;
  margin-top: -8px;
}
.m-btn-active,
.s-btn-active {
  background: #eee;
  color: #404040;
  border: 1px solid #ccc;
  filter: none;
}
.m-btn-plain-active,
.s-btn-plain-active {
  background: transparent;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.m-btn .l-btn-left .l-btn-text {
  margin-right: 20px;
}
.m-btn .l-btn-icon-right .l-btn-text {
  margin-right: 40px;
}
.m-btn .l-btn-icon-right .l-btn-icon {
  right: 20px;
}
.m-btn .l-btn-icon-top .l-btn-text {
  margin-right: 4px;
  margin-bottom: 14px;
}
.m-btn .l-btn-icon-bottom .l-btn-text {
  margin-right: 4px;
  margin-bottom: 34px;
}
.m-btn .l-btn-icon-bottom .l-btn-icon {
  top: auto;
  bottom: 20px;
}
.m-btn .l-btn-icon-top .m-btn-downarrow,
.m-btn .l-btn-icon-bottom .m-btn-downarrow {
  top: auto;
  bottom: 0px;
  left: 50%;
  margin-left: -8px;
}
.m-btn-line {
  display: inline-block;
  position: absolute;
  font-size: 1px;
  display: none;
}
.m-btn .l-btn-left .m-btn-line {
  right: 0;
  width: 16px;
  height: 500px;
  border-style: solid;
  border-color: #ccc;
  border-width: 0 0 0 1px;
}
.m-btn .l-btn-icon-top .m-btn-line,
.m-btn .l-btn-icon-bottom .m-btn-line {
  left: 0;
  bottom: 0;
  width: 500px;
  height: 16px;
  border-width: 1px 0 0 0;
}
.m-btn-large .l-btn-icon-right .l-btn-text {
  margin-right: 56px;
}
.m-btn-large .l-btn-icon-bottom .l-btn-text {
  margin-bottom: 50px;
}
.m-btn-downarrow,
.s-btn-downarrow {
  background: url('images/menu_arrows.png') no-repeat 0 center;
}
.m-btn-plain-active,
.s-btn-plain-active {
  border-color: #ccc;
  background-color: #eee;
  color: #404040;
}
.s-btn:hover .m-btn-line,
.s-btn-active .m-btn-line,
.s-btn-plain-active .m-btn-line {
  display: inline-block;
}
.l-btn:hover .s-btn-downarrow,
.s-btn-active .s-btn-downarrow,
.s-btn-plain-active .s-btn-downarrow {
  border-style: solid;
  border-color: #ccc;
  border-width: 0 0 0 1px;
}
.messager-body {
  padding: 10px 10px 30px 10px;
  overflow: auto;
}
.messager-button {
  text-align: center;
  padding: 5px;
}
.messager-button .l-btn {
  width: 70px;
}
.messager-icon {
  float: left;
  width: 32px;
  height: 32px;
  margin: 0 10px 10px 0;
}
.messager-error {
  background: url('images/messager_icons.png') no-repeat scroll -64px 0;
}
.messager-info {
  background: url('images/messager_icons.png') no-repeat scroll 0 0;
}
.messager-question {
  background: url('images/messager_icons.png') no-repeat scroll -32px 0;
}
.messager-warning {
  background: url('images/messager_icons.png') no-repeat scroll -96px 0;
}
.messager-progress {
  padding: 10px;
}
.messager-p-msg {
  margin-bottom: 5px;
}
.messager-body .messager-input {
  width: 100%;
  padding: 4px 0;
  outline-style: none;
  border: 1px solid #dfdfdf;
}
.window-thinborder .messager-button {
  padding-bottom: 8px;
}
.tree {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.tree li {
  white-space: nowrap;
}
.tree li ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tree-node {
  height: 26px;
  white-space: nowrap;
  cursor: pointer;
}
.tree-hit {
  cursor: pointer;
}
.tree-expanded,
.tree-collapsed,
.tree-folder,
.tree-file,
.tree-checkbox,
.tree-indent {
  display: inline-block;
  width: 16px;
  height: 18px;
  margin: 4px 0;
  vertical-align: middle;
  overflow: hidden;
}
.tree-expanded {
  background: url('images/tree_icons.png') no-repeat -18px 0px;
}
.tree-expanded-hover {
  background: url('images/tree_icons.png') no-repeat -50px 0px;
}
.tree-collapsed {
  background: url('images/tree_icons.png') no-repeat 0px 0px;
}
.tree-collapsed-hover {
  background: url('images/tree_icons.png') no-repeat -32px 0px;
}
.tree-lines .tree-expanded,
.tree-lines .tree-root-first .tree-expanded {
  background: url('images/tree_icons.png') no-repeat -144px 0;
}
.tree-lines .tree-collapsed,
.tree-lines .tree-root-first .tree-collapsed {
  background: url('images/tree_icons.png') no-repeat -128px 0;
}
.tree-lines .tree-node-last .tree-expanded,
.tree-lines .tree-root-one .tree-expanded {
  background: url('images/tree_icons.png') no-repeat -80px 0;
}
.tree-lines .tree-node-last .tree-collapsed,
.tree-lines .tree-root-one .tree-collapsed {
  background: url('images/tree_icons.png') no-repeat -64px 0;
}
.tree-line {
  background: url('images/tree_icons.png') no-repeat -176px 0;
}
.tree-join {
  background: url('images/tree_icons.png') no-repeat -192px 0;
}
.tree-joinbottom {
  background: url('images/tree_icons.png') no-repeat -160px 0;
}
.tree-folder {
  background: url('images/tree_icons.png') no-repeat -208px 0;
}
.tree-folder-open {
  background: url('images/tree_icons.png') no-repeat -224px 0;
}
.tree-file {
  background: url('images/tree_icons.png') no-repeat -240px 0;
}
.tree-loading {
  background: url('images/loading.gif') no-repeat center center;
}
.tree-checkbox0 {
  background: url('images/tree_icons.png') no-repeat -208px -18px;
}
.tree-checkbox1 {
  background: url('images/tree_icons.png') no-repeat -224px -18px;
}
.tree-checkbox2 {
  background: url('images/tree_icons.png') no-repeat -240px -18px;
}
.tree-title {
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 2px;
  margin: 4px 0;
  height: 18px;
  line-height: 18px;
}
.tree-node-proxy {
  font-size: 14px;
  line-height: 20px;
  padding: 0 2px 0 20px;
  border-width: 1px;
  border-style: solid;
  z-index: 9900000;
}
.tree-dnd-icon {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 18px;
  left: 2px;
  top: 50%;
  margin-top: -9px;
}
.tree-dnd-yes {
  background: url('images/tree_icons.png') no-repeat -256px 0;
}
.tree-dnd-no {
  background: url('images/tree_icons.png') no-repeat -256px -18px;
}
.tree-node-top {
  border-top: 1px dotted red;
}
.tree-node-bottom {
  border-bottom: 1px dotted red;
}
.tree-node-append .tree-title {
  border: 1px dotted red;
}
.tree-editor {
  border: 1px solid #dfdfdf;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  padding: 0 4px;
  margin: 0;
  width: 80px;
  outline-style: none;
  vertical-align: middle;
  position: absolute;
  top: 0;
}
.tree-node-proxy {
  background-color: #ffffff;
  color: #404040;
  border-color: #dfdfdf;
}
.tree-node-hover {
  background: #eee;
  color: #404040;
}
.tree-node-selected {
  background: #eee;
  color: #2196f3;
}
.tree-node-disabled {
  opacity: 0.5;
  cursor: default;
}
.tree-node-hidden {
  display: none;
}
.inputbox {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
.validatebox-invalid {
  border-color: #c62828;
  background-color: #fff;
  color: #404040;
}
.tooltip {
  position: absolute;
  display: none;
  z-index: 9900000;
  outline: none;
  opacity: 1;
  filter: alpha(opacity=100);
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.tooltip-content {
  font-size: 14px;
}
.tooltip-arrow-outer,
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
}
.tooltip-arrow {
  display: none \9;
}
.tooltip-right .tooltip-arrow-outer {
  left: 0;
  top: 50%;
  margin: -6px 0 0 -13px;
}
.tooltip-right .tooltip-arrow {
  left: 0;
  top: 50%;
  margin: -6px 0 0 -12px;
}
.tooltip-left .tooltip-arrow-outer {
  right: 0;
  top: 50%;
  margin: -6px -13px 0 0;
}
.tooltip-left .tooltip-arrow {
  right: 0;
  top: 50%;
  margin: -6px -12px 0 0;
}
.tooltip-top .tooltip-arrow-outer {
  bottom: 0;
  left: 50%;
  margin: 0 0 -13px -6px;
}
.tooltip-top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin: 0 0 -12px -6px;
}
.tooltip-bottom .tooltip-arrow-outer {
  top: 0;
  left: 50%;
  margin: -13px 0 0 -6px;
}
.tooltip-bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin: -12px 0 0 -6px;
}
.tooltip {
  background-color: #ffffff;
  border-color: #dfdfdf;
  color: #404040;
}
.tooltip-right .tooltip-arrow-outer {
  border-right-color: #dfdfdf;
}
.tooltip-right .tooltip-arrow {
  border-right-color: #ffffff;
}
.tooltip-left .tooltip-arrow-outer {
  border-left-color: #dfdfdf;
}
.tooltip-left .tooltip-arrow {
  border-left-color: #ffffff;
}
.tooltip-top .tooltip-arrow-outer {
  border-top-color: #dfdfdf;
}
.tooltip-top .tooltip-arrow {
  border-top-color: #ffffff;
}
.tooltip-bottom .tooltip-arrow-outer {
  border-bottom-color: #dfdfdf;
}
.tooltip-bottom .tooltip-arrow {
  border-bottom-color: #ffffff;
}
.switchbutton {
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: #2196f3;
  border: 1px solid #2196f3;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.switchbutton-inner {
  display: inline-block;
  overflow: hidden;
  position: relative;
  top: -1px;
  left: -1px;
}
.switchbutton-on,
.switchbutton-off,
.switchbutton-handle {
  display: inline-block;
  text-align: center;
  height: 100%;
  float: left;
  font-size: 14px;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.switchbutton-on {
  background: #eee;
  color: #2196f3;
}
.switchbutton-off {
  background-color: #ffffff;
  color: #404040;
}
.switchbutton-on,
.switchbutton-reversed .switchbutton-off {
  -moz-border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}
.switchbutton-off,
.switchbutton-reversed .switchbutton-on {
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.switchbutton-handle {
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #ffffff;
  color: #404040;
  border: 1px solid #2196f3;
  -moz-box-shadow: 0 0 3px 0 #2196f3;
  -webkit-box-shadow: 0 0 3px 0 #2196f3;
  box-shadow: 0 0 3px 0 #2196f3;
}
.switchbutton-value {
  position: absolute;
  top: 0;
  left: -5000px;
}
.switchbutton-disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.switchbutton-disabled,
.switchbutton-readonly {
  cursor: default;
}
.switchbutton:focus {
  -moz-box-shadow: 0 0 3px 0 #2196f3;
  -webkit-box-shadow: 0 0 3px 0 #2196f3;
  box-shadow: 0 0 3px 0 #2196f3;
  outline: none;
}
.radiobutton {
  position: relative;
  border: 2px solid #2196f3;
  border-radius: 50%;
}
.radiobutton-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #2196f3;
  border-radius: 50%;
  transform: scale(.6);
}
.radiobutton-disabled {
  opacity: 0.6;
}
.radiobutton-value {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  left: -100px;
}
.checkbox {
  position: relative;
  border: 2px solid #2196f3;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.checkbox-checked {
  border: 0;
  background: #2196f3;
}
.checkbox-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.checkbox path {
  stroke-width: 2px;
}
.checkbox-disabled {
  opacity: 0.6;
}
.checkbox-value {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  left: -100px;
}
.sidemenu .tree-hit {
  background-image: none;
}
.sidemenu-default-icon {
  background-image: none;
  width: 0;
}
.sidemenu .accordion .accordion-header,
.sidemenu .accordion .accordion-body {
  border-bottom-color: transparent;
  background: transparent;
}
.sidemenu .accordion .accordion-header {
  color: #404040;
}
.sidemenu .accordion-header .panel-title {
  height: 30px;
  line-height: 30px;
  color: #404040;
}
.sidemenu .accordion-header:hover {
  background: #eee;
  color: #404040;
}
.sidemenu .tree-node-hover {
  background: #eee;
  color: #404040;
}
.sidemenu .tree-node-selected {
  border-right: 2px solid #2196f3;
  color: #2196f3;
  background: #eee;
}
.sidemenu .tree-node {
  height: 40px;
}
.sidemenu .tree-title {
  margin: 11px 0;
}
.sidemenu .tree-node-nonleaf {
  position: relative;
}
.sidemenu .tree-node-nonleaf::after {
  display: inline-block;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  background: url('images/accordion_arrows.png') no-repeat 0 0;
  width: 16px;
  height: 16px;
  right: 5px;
}
.sidemenu .tree-node-nonleaf-collapsed::after {
  background: url('images/accordion_arrows.png') no-repeat -16px 0;
}
.sidemenu-collapsed .panel-icon {
  left: 50%;
  margin-left: -8px;
}
.sidemenu-collapsed .collapsed-icon {
  position: relative;
}
.sidemenu-collapsed .collapsed-text {
  text-align: center;
}
.sidemenu-tooltip {
  padding: 0;
  margin: 0 -12px;
  border: 0;
}
.sidemenu-tooltip.tooltip-left {
  margin: 0 12px;
}
.sidemenu-tooltip .tooltip-arrow-outer,
.sidemenu-tooltip .tooltip-arrow {
  display: none;
}
.timepicker-panel .clock-wrap {
  position: relative;
}
.timepicker-panel .clock {
  position: relative;
  background: #f5f5f5;
  color: #404040;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
}
.timepicker-panel .clock .item {
  width: 32px;
  height: 32px;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
  position: absolute;
  user-select: none;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
}
.timepicker-panel .clock .item-selected {
  background: #2196f3;
  color: #2196f3;
}
.timepicker-panel .clock .hand {
  width: 2px;
  bottom: 50%;
  left: 50%;
  margin-left: -1px;
  top: 20px;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  position: absolute;
  will-change: transform;
  z-index: 1;
  background-color: #2196f3;
}
.timepicker-panel .clock .hand .drag {
  top: -16px;
  left: -15px;
  width: 4px;
  height: 4px;
  border: 14px solid #2196f3;
  position: absolute;
  box-sizing: content-box;
  border-radius: 100%;
  background-color: #2196f3;
}
.timepicker-panel .clock .center {
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  background-color: #2196f3;
}
.timepicker-panel .panel-header {
  height: 70px;
  border: 0;
  font-size: 36px;
  position: relative;
}
.timepicker-panel .body {
  position: relative;
}
.timepicker-panel .panel-header .ampm {
  font-size: 16px;
  padding-left: 10px;
  position: absolute;
  right: 20px;
}
.timepicker-panel .panel-header .sep {
  opacity: 0.6;
}
.timepicker-panel .panel-header .title {
  cursor: pointer;
  opacity: 0.6;
}
.timepicker-panel .panel-header .title:hover {
  opacity: 1.0;
}
.timepicker-panel .panel-header .title-selected,
.timepicker-panel .panel-header .title-selected:hover {
  cursor: default;
  opacity: 1.0;
}
.textbox {
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.textbox-focused {
  border-color: #2196f3;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  -moz-box-shadow: 0 0 3px 0 transparent;
  -webkit-box-shadow: 0 0 3px 0 transparent;
  box-shadow: 0 0 3px 0 transparent;
}
.textbox .textbox-button {
  background-color: transparent;
  color: #2196f3;
}
.textbox .textbox-button-left,
.textbox .textbox-button-right,
.textbox .textbox-button-top,
.textbox .textbox-button-bottom {
  border-color: transparent;
}
.form-floating-label.form-field .textbox-label {
  color: #8d8d8d;
}
.form-floating-label.form-field-focused .textbox-label {
  color: #2196f3;
}
.combo-arrow,
.combo-arrow:hover,
.spinner-arrow-up,
.spinner-arrow-up:hover,
.spinner-arrow-down,
.spinner-arrow-down:hover {
  background-color: transparent;
}
.spinner-arrow.spinner-button-top,
.spinner-arrow.spinner-button-bottom {
  background-color: transparent;
}
.datagrid-header .datagrid-cell,
.datagrid-cell-group {
  color: #a6a6a6;
}
.datagrid-header td,
.datagrid-body td,
.datagrid-footer td {
  border-style: solid;
}
.datagrid-header td,
.datagrid-body td,
.datagrid-footer td {
  border-left-color: transparent;
  border-right-color: transparent;
}
.datagrid-header td[field]:hover,
.datagrid-header .datagrid-field-td:hover {
  background: #eee;
}
.datagrid-row-alt .datagrid-td-rownumber {
  background-color: #fafafa;
}
.datagrid-row-over .datagrid-td-rownumber {
  background-color: #eee;
}
.datagrid-row-selected,
.datagrid-row-selected .datagrid-td-rownumber {
  color: #404040;
  background: #e1e1e1;
}
.datagrid-filter-row .textbox,
.datagrid-editable .textbox {
  border: 1px solid #dfdfdf;
}
.datagrid-editable .textbox-invalid {
  border-color: #c62828;
}
.datagrid-view1 {
  border-right: 1px solid #dfdfdf;
}
.datagrid-view3 {
  border-left: 1px solid #dfdfdf;
}
.tree-node-selected {
  color: #404040;
  background: #e1e1e1;
}
.calendar-header,
.calendar-body th {
  background: transparent;
}
.calendar-selected {
  background: #2196f3;
  color: #fff;
  border-color: transparent;
}
.calendar-selected.calendar-saturday {
  background: #00ee00;
}
.calendar-selected.calendar-sunday {
  background: #CC2222;
}
.calendar-info {
  background: #2196f3;
  color: #fff;
}
.datebox-button a {
  color: #2196f3;
}
.tabs-header,
.tabs-tool {
  padding-top: 0;
}
.tabs li {
  margin-bottom: 0;
}
.tabs li .tabs-inner,
.tabs li .tabs-inner:hover {
  color: #404040;
  background: transparent;
  border-color: transparent;
  border-left-width: 0;
  border-right-width: 0;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.tabs li.tabs-selected .tabs-inner {
  background: transparent;
  border-color: transparent;
  color: #2196f3;
  border-bottom: 2px solid #2196f3;
  border-radius: 0;
  font-weight: normal;
}
.tabs-header-bottom .tabs li .tabs-inner {
  border-top: 2px solid transparent;
}
.tabs-header-bottom .tabs li.tabs-selected .tabs-inner {
  border-top: 2px solid #2196f3;
  border-bottom: 0;
}
.tabs-header-left .tabs li .tabs-inner {
  border-right: 2px solid transparent;
}
.tabs-header-left .tabs li.tabs-selected .tabs-inner {
  border-right: 2px solid #2196f3;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.tabs-header-right .tabs li .tabs-inner {
  border-left: 2px solid transparent;
}
.tabs-header-right .tabs li.tabs-selected .tabs-inner {
  border-left: 2px solid #2196f3;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.l-btn-selected,
.l-btn-selected:hover {
  color: #fff;
}
.slider-handle {
  background: #2196f3;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.menu-shadow,
.combo-p {
  -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  filter: none;
}
.menu {
  padding: 3px 0;
  border: 0;
}
.menu-icon {
  left: 4px;
}
.menu-active {
  border-color: transparent;
  background: #eee;
  border-radius: 0;
}
.menu-active-disabled {
  border-color: transparent;
  background: transparent;
  color: #404040;
}
.window {
  background-color: #fff;
}
.window .window-header .panel-title {
  height: 40px;
  line-height: 40px;
}
.window .window-header .panel-icon {
  left: 10px;
}
.window .window-header .panel-tool {
  right: 10px;
}
.window .window-header .panel-title {
  padding-left: 10px;
}
.window .window-header .panel-with-icon {
  padding-left: 28px;
}
.window .window-header,
.window .window-body,
.window .dialog-toolbar,
.window .dialog-button {
  border-color: transparent;
  background: transparent;
}
.window .dialog-button .l-btn {
  color: #2196f3;
  border-color: transparent;
  background: transparent;
}
.window .dialog-button .l-btn:hover {
  background: #eee;
}
.window-proxy {
  border-color: #ccc;
}
.window-shadow {
  -moz-box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2), 0 13px 19px 2px rgba(0,0,0,0.14), 0 5px 24px 4px rgba(0,0,0,0.12);
  -webkit-box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2), 0 13px 19px 2px rgba(0,0,0,0.14), 0 5px 24px 4px rgba(0,0,0,0.12);
  box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2), 0 13px 19px 2px rgba(0,0,0,0.14), 0 5px 24px 4px rgba(0,0,0,0.12);
  filter: none;
}
.window-mask {
  background: #000;
}
.sidemenu .tree-node-selected {
  border-right: 0;
}
.l-btn-plain {
  color: #2196f3;
}
.textbox .m-btn-active,
.m-btn-active,
.l-btn:hover {
  background: #0c7cd5;
  border-color: #0c7cd5;
  color: #fff;
}
.m-btn-plain-active,
.l-btn-plain:hover {
  background: #eee;
  border-color: #eee;
  color: #2196f3;
}
.l-btn-selected {
  color: #ffffff;
}
.l-btn-selected:hover {
  background: #6ab8f7;
  border-color: #2196f3;
  color: #fff;
}
.pagination-link.l-btn-plain,
.pagination-link.l-btn-plain:hover {
  color: #404040;
}
.pagination-link.l-btn-selected,
.pagination-link.l-btn-selected:hover {
  color: #ffffff;
}
.m-btn .m-btn-downarrow {
  background-image: url('images/menu_arrows2.png');
}
.textbox .m-btn.l-btn .m-btn-downarrow,
.m-btn.l-btn-plain .m-btn-downarrow {
  background-image: url('images/menu_arrows1.png');
}
.textbox .m-btn-active.l-btn .m-btn-downarrow {
  background-image: url('images/menu_arrows2.png');
}
.spinner .l-btn {
  opacity: 0.6;
}
.spinner .l-btn:hover {
  background: transparent;
  border-color: transparent;
  opacity: 1.0;
}
.switchbutton-on {
  background: #2196f3;
  color: #ffffff;
}
.accordion .accordion-header {
  background: #ffffff;
}
.accordion .panel-last > .accordion-header-selected,
.accordion .accordion-header-selected {
  border-bottom-color: transparent;
}
.accordion .accordion-header-selected .panel-title {
  color: #404040;
}
.progressbar {
  border: 0;
  background: #b2dbfb;
}
.progressbar-text {
  color: #404040;
  font-size: 14px;
}
.progressbar-value,
.progressbar-value .progressbar-text {
  background-color: #2196f3;
  color: #fff;
}
.timepicker-panel .clock .item-selected {
  color: #fff;
}
.timepicker-panel .panel-header {
  background-color: #2196f3;
  color: #fff;
}
.timepicker-panel .clock .hand .drag {
  background-color: #fff;
}
