.icon-blank{
	background:url('icons/blank.gif') no-repeat center center;
}
.icon-add{
	background:url('icons/edit_add.png') no-repeat center center;
}
.icon-edit{
	background:url('icons/pencil.png') no-repeat center center;
}
.icon-clear{
	background:url('icons/clear.png') no-repeat center center;
}
.icon-remove{
	background:url('icons/edit_remove.png') no-repeat center center;
}
.icon-save{
	background:url('icons/filesave.png') no-repeat center center;
}
.icon-cut{
	background:url('icons/cut.png') no-repeat center center;
}
.icon-ok{
	background:url('icons/ok.png') no-repeat center center;
}
.icon-no{
	background:url('icons/no.png') no-repeat center center;
}
.icon-cancel{
	background:url('icons/cancel.png') no-repeat center center;
}
.icon-reload{
	background:url('icons/reload.png') no-repeat center center;
}
.icon-search{
	background:url('icons/search.png') no-repeat center center;
}
.icon-print{
	background:url('icons/print.png') no-repeat center center;
}
.icon-help{
	background:url('icons/help.png') no-repeat center center;
}
.icon-undo{
	background:url('icons/undo.png') no-repeat center center;
}
.icon-redo{
	background:url('icons/redo.png') no-repeat center center;
}
.icon-back{
	background:url('icons/back.png') no-repeat center center;
}
.icon-sum{
	background:url('icons/sum.png') no-repeat center center;
}
.icon-tip{
	background:url('icons/tip.png') no-repeat center center;
}
.icon-filter{
	background:url('icons/filter.png') no-repeat center center;
}
.icon-man{
	background:url('icons/man.png') no-repeat center center;
}
.icon-lock{
	background:url('icons/lock.png') no-repeat center center;
}
.icon-more{
	background:url('icons/more.png') no-repeat center center;
}


.icon-mini-add{
	background:url('icons/mini_add.png') no-repeat center center;
}
.icon-mini-edit{
	background:url('icons/mini_edit.png') no-repeat center center;
}
.icon-mini-refresh{
	background:url('icons/mini_refresh.png') no-repeat center center;
}

.icon-large-picture{
	background:url('icons/large_picture.png') no-repeat center center;
}
.icon-large-clipart{
	background:url('icons/large_clipart.png') no-repeat center center;
}
.icon-large-shapes{
	background:url('icons/large_shapes.png') no-repeat center center;
}
.icon-large-smartart{
	background:url('icons/large_smartart.png') no-repeat center center;
}
.icon-large-chart{
	background:url('icons/large_chart.png') no-repeat center center;
}

.icon-arrowdown{
	background:url('icons/arrowdown_blue16.png') no-repeat center center;
}

.icon-arrowup{
	background:url('icons/arrowup_blue16.png') no-repeat center center;
}

.icon-exit{
	background:url('icons/exit16.png') no-repeat center center;
}
.icon-pause{
	background:url('icons/pause_blue16.png') no-repeat center center;
}
.icon-play{
	background:url('icons/play_blue16.png') no-repeat center center;
}
.icon-stop{
	background:url('icons/stop_blue16.png') no-repeat center center;
}
.icon-views{
	background:url('icons/views16.png') no-repeat center center;
}
.icon-view_prjector{
	background:url('icons/view16.png') no-repeat center center;
}
.icon-verify_document{
	background:url('icons/verify_document16.png') no-repeat center center;
}
.icon-cheque{
	background:url('icons/cheque16.png') no-repeat center center;
}
.icon-address_book{
	background:url('icons/address_book16.png') no-repeat center center;
}
.icon-login{
	background:url('icons/login16.png') no-repeat center center;
}
.icon-logout{
	background:url('icons/logout16.png') no-repeat center center;
}
.icon-user_add{
	background:url('icons/user_add16.png') no-repeat center center;
}
.icon-user_properties{
	background:url('icons/user_properties16.png') no-repeat center center;
}
.icon-user_drop{
	background:url('icons/user_drop16.png') no-repeat center center;
}
.icon-user{
	background:url('icons/user16a.png') no-repeat center center;
}

.icon-edit_accounts{
	background:url('icons/edit_accounts16.png') no-repeat center center;
}

.icon-plus{
	background:url('icons/plus16.png') no-repeat center center;
}

.icon-key{
	background:url('icons/key16.png') no-repeat center center;
}

.icon-new_project{
	background:url('icons/new_project16.png') no-repeat center center;
}

.icon-speaker{
	background:url('icons/speaker16.png') no-repeat center center;
}

.icon-arrdown{
	background:url('icons/arrdown16.png') no-repeat center center;
}

.icon-arrup{
	background:url('icons/arrup16.png') no-repeat center center;
}
.icon-arrright{
	background:url('icons/arrright16.png') no-repeat center center;
}
.icon-block{
	background:url('icons/block216.png') no-repeat center center;
}
.icon-pausewhite{
	background:url('icons/pausewhite16.png') no-repeat center center;
}
.icon-next{
	background:url('icons/next16.png') no-repeat center center;
}
.icon-savegray{
	background:url('icons/save16.png') no-repeat center center;
}
.icon-downloadascii{
	background:url('icons/download_ascii16.png') no-repeat center center;
}
.icon-uploadascii{
	background:url('icons/upload_ascii16.png') no-repeat center center;
}